<template>
  <div class="page product-apply">
    <div class="container">
      <van-form ref="applyForm" @submit="onSubmit">
        <van-cell-group inset>
          <h3>订单信息</h3>
          <van-cell :border="false">
            <template #title>
              <span>订单号：</span>{{ payOrderInfo.mchOrderNo }}
            </template>
          </van-cell>

          <van-cell :border="false">
            <template #title>
              <span>下单时间：</span>{{ payOrderInfo.createdAt }}
            </template>
          </van-cell>
        </van-cell-group>
        <van-cell-group inset>
          <h3>选择支付方式</h3>
          <van-radio-group v-model="payType">
            <van-cell-group class="paytype-radio-group" :border="false">
              <van-cell title="微信支付" clickable @click="payType = '1'">
                <van-icon
                  size="24"
                  name="wechat-pay"
                  class="radio-icon"
                  color="#09bb07"
                />
                <template #right-icon>
                  <van-radio name="1" />
                </template>
              </van-cell>
            </van-cell-group>
          </van-radio-group>
        </van-cell-group>
      </van-form>
    </div>
    <div class="end">
      <div class="settle">
        <van-button
          native-type="submit"
          type="info"
          round
          class="settle-submit"
          @click="onSubmit"
          :loading="loading"
          >确认支付</van-button
        >
        支付金额：<strong>{{ payOrderInfo.amount / 100 }}元</strong>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import config from "@/config";
import { getPayOrderInfo, getPayPackage } from "@/api/api";

export default {
  data() {
    return {
      // 订单详情+this.wxCode
      payOrderInfo: {},
      payType: "1",
      amount: 1, // 支付金额默认
      // 获取支付参数
      resData: {},
      loading: false,
    };
  },
  props: {},
  filters: {
    dateToString(value) {
      if (value) {
        let date = new Date(value);
        let year = date.getFullYear();
        let month = (date.getMonth() + 1).toString();
        let day = date.getDate().toString();
        let hour = date.getHours().toString();
        let minute = date.getMinutes().toString();
        let second = date.getSeconds().toString();

        month = month.length == 1 ? "0" + month : month;
        day = day.length == 1 ? "0" + day : day;
        hour = hour.length == 1 ? "0" + hour : hour;
        minute = minute.length == 1 ? "0" + minute : minute;
        second = second.length == 1 ? "0" + second : second;

        return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
      } else {
        return "";
      }
    },
  },
  created() {},
  mounted() {
    this.setPayOrderInfo(true); //获取订单信息 & 调起支付插件
  },
  computed: {},
  components: {},
  methods: {
    onSubmit() {
      this.pay();
    },
    setPayOrderInfo(isAutoPay) {
      const that = this;
      getPayOrderInfo()
        .then((res) => {
          that.payOrderInfo = res;

          // if (isAutoPay) {
          // 	that.pay();
          // }
        })
        .catch((res) => {
          that.$router.push({
            name: config.errorPageRouteName,
            params: { errInfo: res.msg },
          });
        });
    },

    // 支付事件
    pay: function () {
      // 该函数执行效果慢
      let that = this;
      this.loading = true;
      getPayPackage(this.payOrderInfo.amount)
        .then((res) => {
          this.loading = false;
          if (res.code != "0") {
            Toast({
              message: res.msg,
              duration: 2000,
            });
            return;
          }

          if (res.data.orderState != 1) {
            //订单不是支付中，说明订单异常
            Toast({
              message: res.data.errMsg,
              duration: 2000,
            });
            return;
          }
          //alert(JSON.stringify(res.data));
          that.resData = res.data;
          if (res.data.payUrl) {
            window.location.replace(res.data.payUrl);
            return false;
          } else {
            if (typeof WeixinJSBridge == "undefined") {
              if (document.addEventListener) {
                document.addEventListener(
                  "WeixinJSBridgeReady",
                  that.onBridgeReady,
                  false
                );
              } else if (document.attachEvent) {
                document.attachEvent("WeixinJSBridgeReady", that.onBridgeReady);
                document.attachEvent(
                  "onWeixinJSBridgeReady",
                  that.onBridgeReady
                );
              }
            } else {
              that.onBridgeReady();
            }
          }
        })
        .catch((res) => {
          this.loading = false;
          that.$router.push({
            name: config.errorPageRouteName,
            params: { errInfo: res.msg },
          });
        });
    },

    /* 唤醒微信支付*/
    onBridgeReady() {
      let that = this;

      // eslint-disable-next-line no-undef
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        JSON.parse(that.resData.payInfo),
        function (res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            // //重定向
            if (that.payOrderInfo.returnUrl) {
              location.href = that.payOrderInfo.returnUrl;
            } else {
              alert("支付成功！");
              window.WeixinJSBridge.call("closeWindow");
            }
          }
          if (res.err_msg == "get_brand_wcpay_request:cancel") {
            alert("支付取消");
            //window.WeixinJSBridge.call("closeWindow");
          }
          if (res.err_msg == "get_brand_wcpay_request:fail") {
            alert("支付失败:" + JSON.stringify(res));
            window.WeixinJSBridge.call("closeWindow");
          }
          if (res.err_msg == "total_fee") {
            alert("缺少参数");
            window.WeixinJSBridge.call("closeWindow");
          }
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  background-color: #f8f9fb;
}
.product-apply {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.container {
  flex: 1;
  overflow: auto;
  color: #111a34;
  .van-cell-group--inset,
  .insureAdd {
    margin: 10px 16px;
  }
  .insureAdd {
    margin: 0 16px 16px;
    width: 343px;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    color: #1e92ff;
  }
  h3 {
    margin: 10px 0 24px 14px;
    line-height: 27px;
    font-size: 18px;
    color: #121130;
    em {
      position: absolute;
      margin-top: 4px;
      margin-left: 5px;
      display: inline-block;
      min-width: 18px;
      height: 18px;
      line-height: 18px;
      text-align: center;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      color: #fff;
      background-color: #1e92ff;
      border-radius: 9px;
    }
  }
  p {
    line-height: 24px;
    font-size: 14px;
  }
}
// 尾部固定
.end {
  position: relative;
  z-index: 9;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}
.settle,
.settle-submit {
  line-height: 40px;
  overflow: hidden;
}
.settle {
  width: 100%;
  height: 72px;
  padding: 14px 16px 14px 20px;
  font-size: 14px;
  box-sizing: border-box;
  strong {
    font-size: 16px;
    color: #f00;
  }
  .settle-submit {
    float: right;
    height: 40px;
  }
  .van-button__text {
    padding: 0 10px;
  }
}
.paytype-radio-group {
  .van-cell__value {
    position: absolute;
  }
  .van-cell__title {
    padding-left: 30px;
    font-size: 16px;
  }
}
</style>
